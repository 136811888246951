import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import Papa from 'papaparse';
import './index.css';
import GridImportacao from '../../components/GridImportacao'
import { AxiosResponse } from "axios";
import GridSecundarioModelos from '../../components/GridSecundarioModelos'
import FormModeloImportacao from '../../components/FormModeloImportacao'
import FormLogExecucao from '../../components/FormLogExecucao'
import Loading from '../../components/Loading';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import api from "../../Services/api";
import ExportExcel from '../../components/ExportToExcel';

function ImportarDados() {

    const navigate = useNavigate();

    const [qualTitulo, setQualTitulo] = useState(<></>)
    const [qualGrid, setQualGrid] = useState(<></>)
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [dataCSV, setDataCSV] = useState<object[]>([]);
    const [nomeCSV, setNomeCSV] = useState([]);
    const [propriedadeCSV, setPropriedadeCSV] = useState<string[]>([]);
    const [gridImportacao, setGridImportacao] = useState(<></>)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)


        validatToken()

    }, []);




    async function validatToken() {


        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {



                setGridImportacao(<GridImportacao
                    th1='Nome do arquivo'
                    th2='Status'
                    th3='Origem'
                    th4='Quem'
                    th5='Agendamento em'
                    th6='Importado em'
                    th7='Log de execução'
                    endPoint='importregistration/listar'
                    col1='1'
                    col2='4'
                    col3='5'
                    col4='8'
                    col5='3'
                    col6='6'
                    fromAdd='/task/add'
                    fromEdit='/task/edit'
                    btnDelete='S'
                    VisualizarLog={visualizarLog}
                    Atualizar={Atualizar}
                />)

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }


    function fecharModal2() {
        setOpenModal(false)
    }

    const lerDadosArquivo = (e: any) => {

        const arquivo = e.target.files[0];

        if (arquivo) {

            const fileExtension = arquivo.name.split('.').pop().toLowerCase();

            if (fileExtension !== 'csv') {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione um arquivo válido.</span></div>
                    <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

                if (inputFileRef.current) {
                    inputFileRef.current.value = '';
                }
                return;
            } else {

                Papa.parse(arquivo, {
                    encoding: "ISO-8859-1",
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    complete: (result) => {
                        const validData = result.data.filter((row: any) => row.command !== null);

                        if (validData.length > 0) {

                            setPropriedadeCSV(Object.keys(validData[0] as Record<string, any>));
                            setDataCSV(validData as any);
                            setNomeCSV(arquivo.name);

                        } else {

                            if (inputFileRef.current) {
                                inputFileRef.current.value = '';
                            }

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Nenhuma linha válida encontrada no CSV.</span></div>
                                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)
                            setOpenModal(true)
                        }
                    },
                    error: (error: any) => {

                        if (inputFileRef.current) {
                            inputFileRef.current.value = '';
                        }
                        
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Erro ao analisar o CSV.</span></div>
                            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)

                    },
                });
            }
        }
    };






    async function validatTokeninsert() {
        let ret: AxiosResponse;
        let valor: any;


        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {
                insertImportregistration()
            }
        } catch (e: any) {

            if (e.response.data.status === 403) {

                if (e.response === undefined) {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                } else
                    if (e.response.status === 403) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    }
            }
        }
    }

    async function insertImportregistration() {

        let ret: AxiosResponse;

        if (nomeCSV.length > 0) {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)


            const dados = [{
                irg_description: nomeCSV,
                age_id_insert: secureLocalStorage.getItem('iag'), //inserir usuário 
                irg_status: 'Pendente',
                irg_origin: 'Manual',
            }]

            const _user = secureLocalStorage.getItem('t') as ''


            try {
                ret = await api.post("import/importation", {
                           dataCSV: dataCSV,
                            nomeCSV: dados,
                });
                if (ret.data.status === 200) {
                    window.location.reload();
                }
            } catch (e: any) {

                console.log(e.response.data.message)

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }

        }

    }

    function finalizarModal2() {
        setOpenModal(false)
    }




    function ChamarVisualizacao(obj: any) {


        setQualGrid(<><FormModeloImportacao Data={obj} /></>)
    }


    function AbrirModelo(form: any) {
        setQualGrid((<><GridSecundarioModelos Iconeadd={ChamarVisualizacao}
            th1='Ativo'
            th2='Descrição'
            th3='Prefixo do arquivo *'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='importmodels/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
        /></>)
        )

        setQualTitulo(<><h1 className='modal-tit'>Gerenciar Modelos</h1></>)
        setOpenModalIncluirNovo(true)

    }


    function visualizarLog(obj: any) {

        setQualGrid((<><FormLogExecucao label={obj.irg_executionrecord} /></>)
        )
        setQualTitulo(<><h1 className='modal-tit'>Log de Execução</h1></>)
        setOpenModalIncluirNovo(true)
    }

    function Atualizar() {
        window.location.reload();
    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Importar Arquivo</h2>
                            </div>
                            <hr />
                        </div>

                        <div className='ContainerBodyComple'>

                            <div className='import-div'>
                                <div className='import-div-lab'>
                                    <label>Arquivo: </label>
                                </div>
                                <input ref={inputFileRef} type='file' accept='.csv' onChange={lerDadosArquivo} />
                            </div>

                            <button className='dropOpcao-import' onClick={AbrirModelo}>
                                <a>Gerenciar Modelos</a>
                            </button>
                        </div>

                        <div className="Bt-button-cad-imp">
                            <button type="submit" onClick={validatTokeninsert}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Confirmar Importação
                            </button>

                        </div>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Lista de importação</h2>
                            </div>
                            <hr />
                        </div>

                        {gridImportacao}


                    </div>
                </main>
            </div>
            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <div className='title-modal-incluir'>
                    <h1 className="modal-IncluirNovo-title">Gerenciar Modelos</h1>
                    <div onClick={fecharOpenModalIncluirNovo}>
                        <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div className='scrol-modal'>

                    {qualGrid}

                </div>
            </ModalIncluirNovo>




            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>




        </>
    );

}

export default ImportarDados;