
import React, { useState, useEffect } from 'react'
import './index.css'
import { useNavigate } from 'react-router';
import { MdEmail, MdLock } from "react-icons/md"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { AiOutlineMail } from "react-icons/ai"
import { AxiosResponse } from 'axios';
import Swal from 'sweetalert2';
import logo from '../../img/desk.png';
import api from '../../Services/api'
import { useAuth } from '../../context/useAuth';
import logomenu from "../../img/LOGO.svg"
import { FaUserAlt, FaBuilding } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading/index'


function Login() {

   const auth = useAuth();

   const navigate = useNavigate();
   const [company, setCompany] = useState("")
   const [login, setLogin] = useState("")
   const [senha, setSenha] = useState("")
   const [show, setShow] = useState(false)
   const [sucesso, setSucesso] = useState('');
   const [openModal, setOpenModal] = useState(false)
   const [qualAlert, setQualAlert] = useState(<></>)

   useEffect(() => {
      localStorage.removeItem("PrivateRoute");
   }, []);

   const handleClick = (e: any) => {
      e.preventDefault()
      setShow(!show);
   }



   function handleInput(e: any) {
      var ss = e.target.selectionStart;
      var se = e.target.selectionEnd;
      e.target.value = e.target.value.toUpperCase();
      e.target.selectionStart = ss;
      e.target.selectionEnd = se;
   }


   async function ValidarLogin() {

      if (company === '' || login === '' || senha === '') {

         setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Por favor, preencha todos os campos.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
         </div>)

         setOpenModal(true)

      } else {


         setQualAlert(<div className='div-alert'><Loading /></div>)
         setOpenModal(true)

         axios.get('https://tudoapi.mobcode.com.br:3000/ambientes/validar', {
            params: {
               ambiente: company
            }
         })
            .then(async function (response) {


               let endPoint = response.data.data[0].amb_endpointweb

               axios.post(endPoint + 'user/login', {
                  company: company,
                  login: login,
                  senha: senha
                })
                  .then(async function (response) {


                     secureLocalStorage.setItem('logo', response.data.data.user[0].etp_logotipo);
                     secureLocalStorage.setItem('endPoint', endPoint);
                     secureLocalStorage.setItem('t', response.data.userToken);
                     secureLocalStorage.setItem('acp', response.data.data.user[0].acp_integrationid);
                     secureLocalStorage.setItem('n', response.data.data.user[0].age_name);
                     secureLocalStorage.setItem('iag', response.data.data.user[0].age_id);
                     secureLocalStorage.setItem('cy', response.data.data.user[0].etp_id);
                     secureLocalStorage.setItem('tp', response.data.data.user[0].etp_name);

                  
                     api.defaults.headers.common['Authorization'] = response.data.userToken as ''

                      // Armazenando no secureLocalStorage
                      secureLocalStorage.setItem("pms", JSON.stringify(response.data.data.perfilAcesso));
                      
                     navigate('/dashboard');

                  })
                  .catch(function (error) {

                     if (error.response === undefined) {

                        setQualAlert(<div className='div-alert'>
                           <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                           <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                           <div className='div-info-alert'><span className="info-alert">Desculpe, não conseguimos conectar ao servidor no momento.</span></div>
                           <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)


                     } else if (error.response.status === 401) {

                        setQualAlert(<div className='div-alert'>
                           <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                           <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                           <div className='div-info-alert'><span className="info-alert">Desculpe, não encontramos nenhum usuário com as informações fornecidas. Por favor, verifique e tente novamente.</span></div>
                           <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                     } else {

                        setQualAlert(<div className='div-alert'>
                           <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                           <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                           <div className='div-info-alert'><span className="info-alert">Não foi possivel realizar o login!</span></div>
                           <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                     }

                  })

            })
            .catch(async function (error) {


               if (error.response === undefined) {

                  setQualAlert(<div className='div-alert'>
                     <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                     <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                     <div className='div-info-alert'><span className="info-alert">Desculpe, não conseguimos conectar ao servidor no momento.</span></div>
                     <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                  </div>)


               } else if (error.response.status === 401) {

                  setQualAlert(<div className='div-alert'>
                     <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                     <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                     <div className='div-info-alert'><span className="info-alert">Ambiente não encontrado!</span></div>
                     <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                  </div>)

               } else {

                  setQualAlert(<div className='div-alert'>
                     <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                     <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                     <div className='div-info-alert'><span className="info-alert">Não foi possivel realizar o login!</span></div>
                     <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                  </div>)

               }


            });

      }

   }

   function finalizarModal2() {
      setOpenModal(false)
   }


   function forgotpassword() {
      navigate('/passwordrecover');
   }

   return (
      <div className="login">


         <div className="login-right">
            <div className='div-title1'>
               <span className='title-login'>Olá, bem-vindo</span>
            </div>
            <div className='div-title2'>
               <span className='title-login'>ao <span className='title-bord'>Mobcode AssetManager.</span></span>
            </div>

            <div className='container-input'>


               <div className="login-loginInputAmbiente">
                  <FaBuilding className='icon-input' />
                  <input
                     className='mascInput'
                     placeholder="AMBIENTE"
                     value={company}
                     onChange={e => setCompany(e.target.value.toUpperCase())}
                  />
               </div>

               <div className="login-loginInputAmbiente">
                  <FaUserAlt className='icon-input' />
                  <input
                     className='mascInput'
                     placeholder="USUÁRIO"
                     value={login}
                     onChange={e => setLogin(e.target.value.toUpperCase())}
                  />
               </div>

               <div className="login-loginInputPassword">
                  <MdLock className='icon-input' />
                  <input
                     placeholder="SENHA"
                     type={show ? "text" : "password"}
                     value={senha}
                     onChange={e => setSenha(e.target.value)}
                  />
                  <div className="login-eye">
                     {show ? (
                        <HiEye size={20} onClick={handleClick} />
                     ) : (
                        <HiEyeOff size={20} onClick={handleClick} />
                     )}
                  </div>
               </div>

               <div className='container-btn'>
                  <span className='title-senha' onClick={forgotpassword}>Esqueceu sua senha?</span>
                  <button type="submit" onClick={ValidarLogin}>
                     Entrar
                  </button>
               </div>

            </div>
         </div>
         <div className="login-inf">
            <div>
               <img className='img-das3' src={logomenu} alt="Logo" />
            </div>
            <img className='img-dash' src={logo} alt="Logo" />

            <div className='dash-info-dash'>
               <span className='title-bord-icon-dash'>Controle ativos com registro de manutenções corretivas, preventivas, movimentações e inventários de forma eficiente.</span>
            </div>

         </div>

         <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
            <div className='container-modal-alert'>
               {qualAlert}
            </div>
         </ModalAlert>

      </div>
   )
}

export default Login

