import React, { useState, useEffect } from 'react'
import { Draggable } from '@hello-pangea/dnd';
import moment from 'moment';
import styled from '@emotion/styled';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
// import CustomAvatar from '../TableComponents/CustomAvatar'
// import { ReactComponent as RedArrow } from '../../assets/icons/High.svg'
// import { ReactComponent as YellowArrow } from '../../assets/icons/Medium.svg'
// import { ReactComponent as BlueArrow } from '../../assets/icons/Low.svg'

const TaskInformation = styled.div`
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  border: 1px solid rgb(201, 201, 201);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 7px;
  min-height: 50px;
  border-radius: 5px;
  max-width: 311px;
  margin-top: 5px;
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }

  > p{
    font-size: 12px;
  }

  .secondary-details {
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
    margin-top: 2px;
    cursor: pointer;
  }

  

`;


const TaskCard = ({ item, index }) => {

  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }


  function abrirOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(true)
  }

  useEffect(() => {



}, []);


  return (
    <>
      <Draggable key={String(item.tsk_id)} draggableId={String(item.tsk_id)} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TaskInformation onClick={abrirOpenModalIncluirNovo}>
              <>

                <p>ID: {item.tsk_id} - {item.ast_description}{item.loc_description}</p>

                <div className="secondary-details">
                  <p>
                    <span className='secondary-tipo'>Tipo de Tarefa: {item.tty_description}</span>
                  </p>
                </div>
                <div className="secondary-details">
                  <p>
                    <span>{moment(item.tsk_scheduleinitialdatehour.substr(0, 16)).format("DD/MM/YYYY HH:mm")}</span>
                    {item.tsk_schedulefinaldatehour === null || item.tsk_schedulefinaldatehour === "" ? (
                      null
                    ) : <span> - {moment(item.tsk_schedulefinaldatehour.substr(0, 16)).format("DD/MM/YYYY HH:mm")}</span>}



                  </p>
                </div>
              </>
            </TaskInformation>
          </div>
        )}
      </Draggable>
      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">Detalhamento da Tarefa</h1>
            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>

          <div className="cont-ticket">
          
            <div className="detalhamentoTicket">
              <h1>ID: {item.tsk_id}</h1>
              <h1>Ativo / Local: {item.loc_description ? item.loc_description : item.ast_description}</h1>
              <h1>data de Abertura: {item.tsk_scheduleinitialdatehour.substring(8, 10)}/
                                    {item.tsk_scheduleinitialdatehour.substring(5, 7)}/
                                    {item.tsk_scheduleinitialdatehour.substring(0, 4)}
                                    {item.tsk_scheduleinitialdatehour.substring(11, 16)}</h1>
              <h1>Tipo de Tarefa: {item.tty_description}</h1>
              <h1>Solicitante: {item.tsk_requester}</h1>
              <h1>Observação: {item.tsk_observation}</h1>
            </div>
          </div>

        </>
      </ModalIncluirNovo>
    </>
  );
};

export default TaskCard;

// <span className="priority">
// {item.Priority === 'High' ? (<RedArrow />) : item.Priority === 'Medium' ? (<YellowArrow />) : (<BlueArrow />)}
// </span>
// <div><CustomAvatar name={item.Assignee} isTable={false} size={16} /></div>
