import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

// Cria a instância do axios
const api = axios.create();

// Adiciona um interceptor para modificar a baseURL antes de cada requisição
api.interceptors.request.use((config) => {
  try {
    // Busca o endPoint salvo no SecureLocalStorage

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    const endPoint = secureLocalStorage.getItem('endPoint'); // Retorno do tipo: string | number | boolean | object | null

   
    // Verifica se endPoint é uma string válida
    if (typeof endPoint !== 'string') {
      throw new Error('EndPoint não encontrado ou não é uma string válida.');
    }

    // Define o endPoint dinamicamente como a baseURL
    config.baseURL = endPoint;

    // Retorna a configuração modificada
    return config;
  } catch (error) {

    return Promise.reject(error);
  }
});

export default api;
