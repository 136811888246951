import React, { useState, useEffect } from 'react';
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './ProfilePage.css';
import { FaUserCircle } from 'react-icons/fa';
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

function ProfilePage() {

  const [activeTab, setActiveTab] = useState('meus-dados');
  const [optionsAgentGroup, setOptionsAgentGroup] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  const navigate = useNavigate();

  //USUÁRIO
  const [age_name, setAge_name] = useState('');
  const [age_nameIni, setAge_nameIni] = useState('');
  const [age_integrationid, setAge_integrationid] = useState('')
  const [age_integrationidIni, setAge_integrationidIni] = useState('')
  const [age_email, setAge_email] = useState('')
  const [age_observation, setAge_observation] = useState('')

  //SENHA
  const [novaSenha, setNovaSenha] = useState('')
  const [confirmarSenha, setConfirmarSenha] = useState('')

  //EMPRESA
  const [etp_id, setEtp_id] = useState('')
  const [etp_name, setEtp_name] = useState('')
  const [etp_corporatename, setEtp_corporatename] = useState('')
  const [etp_cnpj, setEtp_cnpj] = useState('')
  const [etp_country, setEtp_country] = useState('')
  const [etp_state, setEtp_state] = useState('')
  const [etp_city, setEtp_city] = useState('')
  const [etp_cityneighborhood, setEtp_cityneighborhood] = useState('')
  const [etp_streettype, setEtp_streettype] = useState('')
  const [etp_street, setEtp_street] = useState('')
  const [etp_streetnumber, setEtp_streetnumber] = useState('')
  const [etp_streetcomplement, setEtp_streetcomplement] = useState('')
  const [etp_zipcode, setEtp_zipcode] = useState('')
  const [etp_phoneddd, setEtp_phoneddd] = useState('')
  const [etp_phoneddi, setEtp_phoneddi] = useState('')
  const [etp_phone, setEtp_phone] = useState('')
  const [etp_mobilephoneddd, setEtp_mobilephoneddd] = useState('')
  const [etp_mobilephoneddi, setEtp_mobilephoneddi] = useState('')
  const [etp_mobilephone, setEtp_mobilephone] = useState('')
  const [etp_email, setEtp_email] = useState('')
  const [etp_observation, setEtp_observation] = useState('')
  const [etp_logotipo, setEtp_logotipo] = useState('')
  const [etp_token, setEtp_token] = useState('')

  const [optionsUser, setOptionsUser] = useState([])

  const [dadosUrl, setDadosUrl] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)


    validatToken();

  }, []);



  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

        usereditar();

      }

    } catch (e: any) {

      if (e.response === undefined) {
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
      } else
        if (e.response.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        } else {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)
        }
    }

  }



  async function usereditar() {
    let ret: AxiosResponse;
    let valor: any;


    try {
      ret = await api.get("user/usereditarconf", {
        params: {
          id: secureLocalStorage.getItem('iag')
        }
      })
      if (ret.data.status === 200) {

        await GetUser()
        const agent = ret.data.data.agent[0]
        const enterprise = ret.data.data.enterprise[0]

        setAge_name(agent.age_name || '')
        setAge_nameIni(agent.age_name || '')
        setAge_integrationid(agent.age_integrationid || '')
        setAge_integrationidIni(agent.age_integrationid || '')
        setAge_email(agent.age_email || '')
        setAge_observation(agent.age_observation || '')

        setEtp_id(enterprise.etp_id || '')
        setEtp_name(enterprise.etp_name || '')

        setEtp_corporatename(enterprise.etp_corporatename || '')
        setEtp_cnpj(enterprise.etp_cnpj || '')
        setEtp_country(enterprise.etp_country || '')
        setEtp_state(enterprise.etp_state || '')
        setEtp_city(enterprise.etp_city || '')
        setEtp_cityneighborhood(enterprise.etp_cityneighborhood || '')
        setEtp_streettype(enterprise.etp_streettype || '')
        setEtp_street(enterprise.etp_street || '')
        setEtp_streetnumber(enterprise.etp_streetnumber || '')
        setEtp_streetcomplement(enterprise.etp_streetcomplement || '')
        setEtp_zipcode(enterprise.etp_zipcode || '')
        setEtp_phoneddd(enterprise.etp_phoneddd || '')
        setEtp_phoneddi(enterprise.etp_phoneddi || '')
        setEtp_phone(enterprise.etp_phone || '')
        setEtp_mobilephoneddd(enterprise.etp_mobilephoneddd || '')
        setEtp_mobilephoneddi(enterprise.etp_mobilephoneddi || '')
        setEtp_mobilephone(enterprise.etp_mobilephone || '')
        setEtp_email(enterprise.etp_email || '')
        setEtp_observation(enterprise.etp_observation || '')
        setEtp_token(enterprise.etp_token || '')
        //setEtp_logotipo(enterprise.etp_logotipo || '')

        const logoFromStorage = secureLocalStorage.getItem('logo') as string; // Recupera a logo

        // Verifica se a URL da imagem é válida
        const image = new Image();
        image.src = logoFromStorage;

        image.onload = () => {
          setImagePreviewUrl(logoFromStorage); // Define a logo se for válida
        };

        image.onerror = () => {
          setImagePreviewUrl(''); // Define como null se não for válida
        };

        setOpenModal(false)

      }
    } catch (e) {
      setOpenModal(false)

    }
  }


  async function GetUser() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("user/verificacao")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        setOptionsUser(valor)

      }
    } catch (e) {
      setOptionsUser([])
    }
  }

  const maskOnlyNumbers = (value: any) => {
    return value.replace(/\D/g, "");
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '30px',
      height: '30px',
      borderColor: state.isFocused ? 'grey' : '#92989e',

    }),


    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
    }),
  };

  function fecharModal() {

    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)

  }

  function fecharModal2() {
    setOpenModal(false)
  }

  async function EditarUser() {


    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    try {

      ret = await api.get("/status/validar")

      const FiltrarNome = optionsUser.filter(
        (e: any) => {
          return e.age_name.toLowerCase() === age_name.toLowerCase()
        }
      );

      const Filtrarintegrationid = optionsUser.filter(
        (e: any) => {
          return String(e.age_integrationid || "").toLowerCase() === age_integrationid.toLowerCase()
        }
      );


      if (age_nameIni.toLowerCase() !== age_name.toLowerCase()
        && FiltrarNome.length > 0) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">O nome já está em uso.</span></div>
          <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else

        if (age_integrationid !== '' && age_integrationid.toLowerCase() !== age_integrationidIni.toLowerCase()
          && Filtrarintegrationid.length > 0) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">ID para Integração já está em uso.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        } else
          if (ret.data.status === 200) {
            if (age_name === "") {
              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o nome.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
              </div>)
            } else if (age_email === "") {
              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o E-mail.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
              </div>)
            } else {


              const dados = {
                age_id: secureLocalStorage.getItem('iag'),
                age_name,
                age_integrationid: age_integrationid || null,
                age_email: age_email,
                age_observation: age_observation || null,
              }


              try {
                ret = await api.put("user/updateagent", {
                  data: dados,
                });
                if (ret.data.status === 200) {



                  navigate('/dashboard');

                }
              } catch (e: any) {


                if (e.response === undefined) {
                  setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                  </div>)

                  setOpenModal(true)
                } else
                  if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                      <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                      <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                  } else {
                    setQualAlert(<div className='div-alert'>
                      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                      <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                      <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                  }
              }










            }

          }

    } catch (e: any) {


      if (e.response === undefined) {
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
      } else
        if (e.response.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        } else {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)
        }
    }

  }

  async function EditarSenha() {


    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {
        if (novaSenha === "") {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite a nova senha.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)
        } else if (confirmarSenha === "") {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Confirme a nova senha.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)
        } else if (novaSenha !== confirmarSenha) {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">As senhas inseridas não correspondem. Verifique e tente novamente.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)
        }




        else {


          const dados = {
            age_id: secureLocalStorage.getItem('iag'),
            age_password: novaSenha,
          }


          try {
            const ret = await api.post("user/trocarsenhaconf", {
              data: dados,
            });
            if (ret.data.status === 200) {

              setNovaSenha('')
              setConfirmarSenha('')

              secureLocalStorage.setItem('t', ret.data.userToken);
              secureLocalStorage.setItem('acp', ret.data.data[0].acp_integrationid);
              secureLocalStorage.setItem('n', ret.data.data[0].age_name);
              secureLocalStorage.setItem('iag', ret.data.data[0].age_id);
              secureLocalStorage.setItem('cy', ret.data.data[0].age_company);






              navigate('/dashboard');

            }
          } catch (e: any) {

            if (e.response === undefined) {
              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
              </div>)

              setOpenModal(true)
            } else
              if (e.response.status === 403) {

                setQualAlert(<div className='div-alert'>
                  <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                  <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

              } else {
                setQualAlert(<div className='div-alert'>
                  <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                  <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
              }
          }
        }

      }

    } catch (e: any) {

      if (e.response === undefined) {
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
      } else
        if (e.response.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        } else {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)
        }
    }

  }

  function base64ToFile(base64Data: any, fileName: any) {
    const byteCharacters = atob(base64Data.split(',')[1]); // Decodifica a parte base64
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Obtém o tipo de conteúdo
    const contentType = base64Data.split(';')[0].split(':')[1];

    // Cria um novo File a partir do Blob
    return new File([new Blob([byteNumbers], { type: contentType })], fileName, {
      type: contentType,
    });
  }

  async function EditarEmpresa() {


    let ret1: AxiosResponse;
    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    try {

      ret1 = await api.get("/status/validar")

      if (ret1.data.status === 200) {

        if (etp_name === "") {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite o nome.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)
        }

        else {



          const formData = new FormData();


          const endPoint = secureLocalStorage.getItem('endPoint')

          const ambiente = secureLocalStorage.getItem('cy');


          const ambienteString = typeof ambiente === 'string'
            ? ambiente.toLowerCase()
            : String(ambiente).toLowerCase();

          if (imagePreviewUrl) {
            const fileName = `${Date.now()}.png`; // Nome do arquivo com timestamp
            const file = base64ToFile(imagePreviewUrl, fileName); // Converte para File

            formData.append('files', file); // Adiciona o File ao FormData
            formData.append('ambiente', ambienteString);
          }

          const _user = secureLocalStorage.getItem('t')

          api.defaults.headers.common['Authorization'] = _user as ''


          axios({
            method: "post",
            url: endPoint + 'upload',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          }).then(async function (response) {





            const dados = {
              etp_id: etp_id,
              etp_name: etp_name,
              etp_corporatename: etp_corporatename || null,
              etp_cnpj: etp_cnpj || null,
              etp_country: etp_country || null,
              etp_state: etp_state || null,
              etp_city: etp_city || null,
              etp_cityneighborhood: etp_cityneighborhood || null,
              etp_streettype: etp_streettype || null,
              etp_street: etp_street || null,
              etp_streetnumber: etp_streetnumber || null,
              etp_streetcomplement: etp_streetcomplement || null,
              etp_zipcode: etp_zipcode || null,
              etp_phoneddd: etp_phoneddd || null,
              etp_phoneddi: etp_phoneddi || null,
              etp_phone: etp_phone || null,
              etp_mobilephoneddd: etp_mobilephoneddd || null,
              etp_mobilephoneddi: etp_mobilephoneddi || null,
              etp_mobilephone: etp_mobilephone || null,
              etp_email: etp_email || null,
              etp_observation: etp_observation || null,
              age_id_lastupdate: secureLocalStorage.getItem('iag'),
              etp_logotipo: response.data.results[0].link
            }


            try {
              ret = await api.put("enterprise/update", {
                data: dados,
              });
              if (ret.data.status === 200) {

                secureLocalStorage.setItem('logo', response.data.results[0].link);

                navigate('/dashboard');

              }
            } catch (e: any) {
              if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                  <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                  <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
              } else
                if (e.response.status === 403) {

                  setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                  </div>)

                  setOpenModal(true)

                } else {
                  setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                    <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                  </div>)

                  setOpenModal(true)
                }
            }


          })
            .catch(async function (error) {




            });




        }

      }

    } catch (e: any) {

      if (e.response === undefined) {
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
      } else
        if (e.response.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        } else {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)
        }
    }

  }

  function validarcnpj(cnpj: any) {
    var valor = cnpj

    valor = valor + '';
    valor = valor.replace(/\D/g, "");
    valor = valor + '';

    if (valor == 'NaN') {
      setEtp_cnpj('');
    } else {
      if (valor.length > 14) {

      } else {
        setEtp_cnpj(valor.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"))
      }

    }
  }

  function alterarFoto() {
    setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'><span className="info-alert">Função não disponível no momento.</span></div>
      <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)

    setOpenModal(true)
  }


  function limpar() {

    setImagePreviewUrl("")

    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }

  }

  const uploadImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const extensoesPermitidas = /\.(jpg|jpeg|png|gif)$/i;

    if (file) {
      if (extensoesPermitidas.test(file.name)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        e.target.value = '';
        setQualAlert(
          <div className="div-alert">
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className="div-info-alert-atencao">
              <span className="info-alert-atencao">ATENÇÃO</span>
            </div>
            <div className="div-info-alert">
              <span className="info-alert">
                Apenas arquivos JPG, JPEG, PNG e GIF são permitidos.
              </span>
            </div>
            <div className="btn-alert" onClick={fecharModal2}>
              <span className="btn-alert-lab">OK</span>
            </div>
          </div>
        );
        setOpenModal(true);
      }
    }
  };




  const renderTabContent = () => {
    switch (activeTab) {
      case 'meus-dados':
        return (
          <div className="tab-content">


            <div className="container-cad-prod50">
              <div id="content1">

                <div className="dados-container">

                  <div className="input-box-ativos">
                    <span className="details">Nome *</span>
                    <input
                      id="description"
                      type="text"
                      autoComplete="nope"
                      onChange={(e) => setAge_name(e.target.value)}
                      value={age_name}
                    />
                  </div>

                  <div className="input-box-ativos">
                    <span className="details">ID para Integração</span>
                    <input
                      id="description"
                      type="text"
                      autoComplete="nope"
                      onChange={(e) => setAge_integrationid(e.target.value)}
                      value={age_integrationid}
                    />
                  </div>


                  <div className="input-box-ativos">
                    <span className="details">E-mail *</span>
                    <input
                      id="description"
                      type="text"
                      autoComplete="nope"
                      onChange={(e) => setAge_email(e.target.value)}
                      value={age_email}
                    />
                  </div>

                  <div className="textareaBox-ativo">
                    <span className="details">Observação</span>
                    <textarea
                      onChange={(e) => setAge_observation(e.target.value)} value={age_observation}
                    />
                  </div>

                </div>
              </div>


              <div id="content2">
                <div className="foto-container">
                  <div className="foto-perfil"><FaUserCircle style={{ fontSize: '180px', color: '#dddbdb' }} /></div>
                  <button className="alterar-foto-btn" onClick={alterarFoto}>Alterar foto</button>
                </div>
              </div>
            </div>

            <div className="button-container">
              <button className="save-button" onClick={EditarUser}>Salvar</button>
            </div>


          </div>
        );
      case 'seguranca':
        return (
          <div className="tab-content">

            <div className="input-box-ativos">
              <span className="details">Nova senha *</span>
              <input
                type="password"
                autoComplete="nope"
                onChange={(e) => setNovaSenha(e.target.value)}
                value={novaSenha}
              />
            </div>

            <div className="input-box-ativos">
              <span className="details">Confirmação da senha *</span>
              <input
                type="password"
                autoComplete="nope"
                onChange={(e) => setConfirmarSenha(e.target.value)}
                value={confirmarSenha}
              />
            </div>

            <div className="button-container">
              <button className="save-button"  onClick={EditarSenha}>Salvar</button>
            </div>


          </div>
        );
      case 'empresa':
        return (
          <div className="tab-content">

            <div className="container-cad-prod50">
              <div id="content1">
                <div className="input-box-ativos">
                  <span className="details">Nome *</span>
                  <input
                    id="description"
                    type="text"
                    autoComplete="nope"
                    onChange={(e) => setEtp_name(e.target.value)}
                    value={etp_name}
                  />
                </div>

                <div className="input-box-ativos">
                  <span className="details">Razão social</span>
                  <input
                    id="description"
                    type="text"
                    autoComplete="nope"
                    onChange={(e) => setEtp_corporatename(e.target.value)}
                    value={etp_corporatename}
                  />
                </div>

                <div className="input-box-ativos">
                  <span className="details">CNPJ</span>
                  <input
                    id="description"
                    type="text"
                    autoComplete="nope"
                    onChange={(e) => validarcnpj(e.target.value)}
                    value={etp_cnpj}
                  />
                </div>

                <div className="input-box-ativos">
                  <span className="details">E-mail</span>
                  <input
                    id="description"
                    type="text"
                    autoComplete="nope"
                    onChange={(e) => setEtp_email(e.target.value)}
                    value={etp_email}
                  />
                </div>

                <div className="container-cad-prod51">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">DDI</span>
                      <input
                        autoComplete="nope"
                        maxLength={3}
                        type="text"
                        onChange={(e) => setEtp_mobilephoneddi(maskOnlyNumbers(e.target.value))}
                        value={etp_mobilephoneddi}
                      />
                    </div>
                  </div>
                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">DDD</span>
                      <input
                        autoComplete="nope"
                        maxLength={3}
                        type="text"
                        onChange={(e) => setEtp_mobilephoneddd(maskOnlyNumbers(e.target.value))}
                        value={etp_mobilephoneddd}
                      />
                    </div>
                  </div>
                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Celular</span>
                      <input
                        autoComplete="nope"
                        maxLength={18}
                        type="text"
                        onChange={(e) => setEtp_mobilephone(maskOnlyNumbers(e.target.value))}
                        value={etp_mobilephone}
                      />
                    </div>
                  </div>
                </div>




                <div className="container-cad-prod51">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">DDI</span>
                      <input
                        autoComplete="nope"
                        maxLength={3}
                        type="text"
                        onChange={(e) => setEtp_phoneddi(maskOnlyNumbers(e.target.value))}
                        value={etp_phoneddi}
                      />
                    </div>
                  </div>
                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">DDD</span>
                      <input
                        autoComplete="nope"
                        maxLength={3}
                        type="text"
                        onChange={(e) => setEtp_phoneddd(maskOnlyNumbers(e.target.value))}
                        value={etp_phoneddd}
                      />
                    </div>
                  </div>
                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Telefone</span>
                      <input
                        autoComplete="nope"
                        maxLength={18}
                        type="text"
                        onChange={(e) => setEtp_phone(maskOnlyNumbers(e.target.value))}
                        value={etp_phone}
                      />
                    </div>
                  </div>
                </div>


                <div className="container-cad-prod52">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">País</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_country(e.target.value)}
                        value={etp_country}
                      />
                    </div>
                  </div>
                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Estado</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_state(e.target.value)}
                        value={etp_state}
                      />
                    </div>
                  </div>
                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Cidade</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_city(e.target.value)}
                        value={etp_city}
                      />
                    </div>
                  </div>
                </div>


                <div className="container-cad-prod53">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Bairro</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_cityneighborhood(e.target.value)}
                        value={etp_cityneighborhood}
                      />
                    </div>
                  </div>
                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Tipo de Logradouro</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_streettype(e.target.value)}
                        value={etp_streettype}
                      />
                    </div>
                  </div>
                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Logradouro</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_street(e.target.value)}
                        value={etp_street}
                      />
                    </div>
                  </div>
                </div>

                <div className="container-cad-prod52">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Número</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_streetnumber(e.target.value)}
                        value={etp_streetnumber}
                      />
                    </div>
                  </div>
                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Complemento</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_streetcomplement(e.target.value)}
                        value={etp_streetcomplement}
                      />
                    </div>
                  </div>
                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">CEP</span>
                      <input
                        id="description"
                        type="text"
                        autoComplete="nope"
                        onChange={(e) => setEtp_zipcode(e.target.value)}
                        value={etp_zipcode}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className="textareaBox-ativo">
                <span className="details">Observação</span>
                <textarea
                  onChange={(e) => setEtp_observation(e.target.value)} value={etp_observation}
                />
              </div>

              <div id="content2">
                <div className="foto-container">
                  <div className="logo-perfil">
                    {imagePreviewUrl ? (
                      <img src={imagePreviewUrl} alt="Logo Preview" className="logo-perfil" />
                    ) : (
                      <h1>Seu Logotipo</h1>
                    )}
                  </div>

                  <div className="button-container">
                    <button
                      className="alterar-foto-btn"
                      onClick={() => document.getElementById('fileInput')?.click()}
                    >
                      Alterar logo
                    </button>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={uploadImg}
                    />
                    <button
                      className="alterar-foto-btn"
                      onClick={limpar}
                    >
                      Limpar
                    </button>
                  </div>




                </div>
              </div>

            </div>

            <div className="button-container">
              <button className="save-button" onClick={EditarEmpresa}>Salvar</button>
            </div>


          </div>
        );
      case 'API':
        return (
          <div className="tab-content">
            <div className="container-cad-prod54">
              <div className="input-box-ativos">
                <span className="details">Token de acesso a API </span>
                <input
                  type="text"
                  autoComplete="nope"
                  readOnly
                  onChange={(e) => setEtp_token(e.target.value)}
                  value={etp_token}
                />
              </div>
            </div>
            <div className="button-container">
              <button className="save-button" onClick={gerarToken}>Gerar Token</button>
            </div>

          </div>
        );
      default:
        return null;
    }
  };

 async function gerarToken(){
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("token/create", {
        params: {
          id: secureLocalStorage.getItem('iag')
        }
      })
        if (ret.status === 200) {
   
          
          window.location.reload()

        }
       
    } catch (e:any) {
      if (e.response === undefined) {
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
      } else
        if (e.response.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        } else {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)
        }
    }
  }

  return (


    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>

          <div className='containerPrincipal'>
            <div className='grid-container-principal'>
              <div className="profile-page">
                <div className="tabs-conf">
                  <button
                    className={`tab-button ${activeTab === 'meus-dados' ? 'active' : ''}`}
                    onClick={() => setActiveTab('meus-dados')}
                  >
                    Meus Dados
                  </button>
                  <button
                    className={`tab-button ${activeTab === 'seguranca' ? 'active' : ''}`}
                    onClick={() => setActiveTab('seguranca')}
                  >
                    Segurança
                  </button>
                  <button
                    className={`tab-button ${activeTab === 'empresa' ? 'active' : ''}`}
                    onClick={() => setActiveTab('empresa')}
                  >
                    Empresa
                  </button>
                  <button
                    className={`tab-button ${activeTab === 'API' ? 'active' : ''}`}
                    onClick={() => setActiveTab('API')}
                  >
                    API
                  </button>
                </div>
                <div className="tab-panel">
                  {renderTabContent()}
                </div>

              </div>

            </div>
          </div>




        </main>
      </div>


      <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalPrincipal>

    </>


  );
}

export default ProfilePage;
