import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import ModalPrincipal from '../ModalAlert/modal'
import Loading from '../Loading';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import Select from 'react-select'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ModalIncluirNovo from '../ModalIncluirNovo/modal'
import Accordion from '../Accordion/Accordion'
import { RiOpenSourceLine } from 'react-icons/ri';

function FormDuracao(props: any) {

    const data = {
        "Funções": [
            { id: "ativarduracao", label: 'Funções: Ativar duração' },
        ],
    };

    const operadorComparacao = {
        "Operador": [
            { id: " === ", label: "Igual", tipo: 'operadorComparacao' },
            { id: " !== ", label: "Diferente", tipo: 'operadorComparacao' },
            { id: " > ", label: "Maior", tipo: 'operadorComparacao' },
            { id: " < ", label: "Menor", tipo: 'operadorComparacao' },
            { id: " > = ", label: "Maior ou Igual", tipo: 'operadorComparacao' },
            { id: " < = ", label: "Menor ou Igual", tipo: 'operadorComparacao' },
        ],
    }


    const operador = {
        "Operador": [
            { id: " && ", label: "AND", tipo: 'operador' },
            { id: " || ", label: "OR", tipo: 'operador' },
        ],
    }

    interface Objeto {
        id: number;
        nome: string;
        tipo?: string; // Propriedade opcional
    }

    const [tabIndex, setTabIndex] = useState(0);

    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [openModalIncluirNovo2, setOpenModalIncluirNovo2] = useState(false)
    const [qualTitulo, setQualTitulo] = useState('')
    const [qualGrid, setQualGrid] = useState(<></>)
    //---------------------------padrão---------------------------------

    const [asf_active, setAsf_active] = useState(props.dados.asf_active != undefined ? Boolean(props.dados.asf_active) : true)
    const [asf_visible, setAsf_visible] = useState(props.dados.asf_visible != undefined ? Boolean(props.dados.asf_visible) : true)
    const [asf_editable, setAsf_editable] = useState(props.dados.asf_editable != undefined ? Boolean(props.dados.asf_editable) : true)
    const [asf_acceptnull, setAsf_acceptnull] = useState(props.dados.asf_acceptnull != undefined ? Boolean(props.dados.asf_acceptnull) : true)

    const [fdm_id, setFdm_id] = useState(String(props.dados.fdm_id || ''))

    const [asf_description, setAsf_description] = useState(props.dados.asf_description || '')
    const [descriptionUp, setDescriptionUp] = useState(props.dados.asf_description || '')
    const [asf_label, setAsf_label] = useState(props.dados.asf_label || '')

    const [asf_alternativeidentifier, setasf_alternativeidentifier] = useState(props.dados.asf_alternativeidentifier || '')
    const [alternativeidentifieUp, setAlternativeidentifieUp] = useState(props.dados.asf_alternativeidentifier || '')
    const [asf_tip, setAsf_tip] = useState(props.dados.asf_tip || '')
    const [asf_size, setAsf_size] = useState(props.dados.asf_size || '')

    const [asf_observation, setSf_observation] = useState(props.dados.asf_observation || '')
    //--------------------------------Lista----------------------------
    const [asf_typelist, setAsf_typelist] = useState(props.dados.asf_typelist || '')
    const [mmc_id, setMmc_id] = useState(props.dados.mmc_id || '')
    //--------------------------label----------------------------------
    const [asf_fontformat, setAsf_fontformat] = useState(props.dados.asf_fontformat || '')
    const [asf_fontsize, seAasf_fontsize] = useState(props.dados.asf_fontsize || '')
    //--------------------------logico----------------------------------
    const [asf_truevalue, setAsf_truevalue] = useState(props.dados.asf_truevalue || '')
    const [asf_falsevalue, seAsf_falsevalue] = useState(props.dados.asf_falsevalue || '')
    //------------------------------------------------------------
    const [asf_valueformulas, setAsf_valueformulas] = useState(props.dados.asf_valueformulas || '')
    const [asf_labelvalueformulas, setAsf_labelvalueformulas] = useState(props.dados.asf_labelvalueformulas || '')
    const [asf_function, setAsf_function] = useState(props.dados.asf_function || '')

    const [optionsFdm, setOptionsFdm] = useState([])

    const [optionsCampos, setOptionsCampos] = useState([])

    const [etapasvalidationformulas, setEtapasvalidationformulas] = useState('0')
    const [realetapasvalidationformulas, setRealEtapasvalidationformulas] = useState('0')

    const [transactionsList, setTransactionsList] = useState<Objeto[]>([]);
    const [totalCampos, setTotalCampos] = useState([]);

    useEffect(() => {

        GetMascara();

        if (props.listaCampos.length > 0) {
            construirArray(props.listaCampos)
        }

        if (props.dados.asf_validationformulasobj === undefined || props.dados.asf_validationformulasobj === null) {         
          
        }else{
            setEtapasvalidationformulas(String(3))
            if (
                props.dados.asf_validationformulasobj !== '' &&
                props.dados.asf_validationformulasobj !== null &&
                props.dados.asf_validationformulasobj !== undefined
              ) {
                setTransactionsList(JSON.parse(props.dados.asf_validationformulasobj));
              }
        }

   
    }, []);

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        // Remove espaços antes e depois, e impede a inserção de espaços
        const trimmedValue = value.replace(/\s+/g, '');
        setasf_alternativeidentifier(trimmedValue);
    };

    function construirArray(data: any) {

        const filteredArray = data.filter((item: any) => item.id !== props.dados.id);

        let transformedArray: any

        transformedArray = {
            Campos: filteredArray.map((item: any) => ({
                id: item.asf_alternativeidentifier,
                label: item.asf_description,
                tipo: 'campo'
            }))
        };

        setTotalCampos(filteredArray)
        setOptionsCampos(transformedArray)

    }

    function pegarValorFormulaValor2(data: any) {

        let tipoV: any

        if (etapasvalidationformulas === '3') {
            tipoV = 0
        } else {
            tipoV = Number(etapasvalidationformulas) + 1
        }

        let tipoR = Number(realetapasvalidationformulas) + 1

        setRealEtapasvalidationformulas(String(tipoR))
        setEtapasvalidationformulas(String(tipoV))

        const newArrayTransactions = [...transactionsList, data];
        let transfor = JSON.stringify(newArrayTransactions)
        setTransactionsList(JSON.parse(transfor));

        setOpenModalIncluirNovo2(false)

    }


    function pegarValorFormulaValor(data: any) {
        setOpenModalIncluirNovo(false)
        setAsf_valueformulas(data.id)
        setAsf_labelvalueformulas(data.label)
        setAsf_function('return true;')
    }

    function limparValorFormulaValor() {
        setAsf_valueformulas('')
        setAsf_labelvalueformulas('')
        setAsf_function('')
    }


    function limparValorFormulaValor2() {

        let tipoV: any

        if (transactionsList.length > 0) {
            const ultimoObjeto = transactionsList[transactionsList.length - 1];

            if(ultimoObjeto.tipo === 'operador'){
                tipoV = 3
            }else{
                tipoV = Number(etapasvalidationformulas) - 1
            }

        } else {
            tipoV = 0
        }



        setEtapasvalidationformulas(String(tipoV))

        const novoArray = transactionsList.slice(0, -1);
        setTransactionsList(novoArray)

    }

    async function GetMascara() {
        let ret;
        let valor;
        try {
            ret = await api.get("fieldmask/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.fdm_id), label: user.fdm_description });
                });
                setOptionsFdm(arr)

            }
        } catch (e) {
            setOptionsFdm([])
        }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-active") as HTMLInputElement;

        if (e.checked) {
            setAsf_active(true)
        } else {
            setAsf_active(false)
        }
    }

    function ValitarVisible() {
        let e = document.getElementById("checkbox-active-visible") as HTMLInputElement;

        if (e.checked) {
            setAsf_visible(true)
        } else {
            setAsf_visible(false)
        }
    }

    function ValitarEditable() {
        let e = document.getElementById("checkbox-active-editable") as HTMLInputElement;

        if (e.checked) {
            setAsf_editable(true)
        } else {
            setAsf_editable(false)
        }
    }

    function ValitarAcceptnull() {
        let e = document.getElementById("checkbox-active-acceptnull") as HTMLInputElement;

        if (e.checked) {
            setAsf_acceptnull(true)
        } else {
            setAsf_acceptnull(false)
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function fecharModal() {
        setOpenModal(false)
    }


    function geraStringAleatoria() {
        var stringAleatoria = '';
        var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < 8; i++) {
            stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        return stringAleatoria;
    }

    function salvarCampo() {

        let ultimoObjeto: any

        if (transactionsList.length > 0) {
             ultimoObjeto = transactionsList[transactionsList.length - 1];

            if(ultimoObjeto.tipo === 'campo' 
            || ultimoObjeto.tipo === 'operadorComparacao' 
            || ultimoObjeto.tipo === 'operador'){
                ultimoObjeto = transactionsList[transactionsList.length - 1]
            }else{
                ultimoObjeto = []
            }
        }else{
            ultimoObjeto = []
        }

        if (asf_description === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (asf_alternativeidentifier === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        }else if (ultimoObjeto.length !== 0 ) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">É necessário que você conclua a fórmula de validação.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else {

          
            if (props.dados.length === 0) {

                let asf_condition: null | string = "";
                let func: string | null = null;
                let transfor: any
                let asf_validationformulas: any | string = '';
                let asf_validationformulas2: any | string = '';


                if (asf_visible === true && transactionsList.length === 0) {
                    asf_condition = 'return true;'
                }

                if (transactionsList.length !== 0) {
                    
                        transactionsList.map((item: any, index: any) => {



                            if (item.tipo === 'campo') {
                                asf_condition += 'formData.' + item.id; // Adiciona um espaço após
                                asf_validationformulas += item.id + '_'
                                
                            } else if (item.tipo === 'operadorComparacao') {
                                asf_condition += item.id; // Adiciona um espaço após
                            } else if (item.tipo === 'constante') {
                                asf_condition += '"' + item.id + '" '; // Adiciona um espaço após
                            } else if (item.tipo === 'operador') {
                                asf_condition += item.id; // Adiciona um espaço após
                            }



                        })
                    

                    asf_condition = 'return ' + asf_condition

                    transfor = JSON.stringify(transactionsList)

                    asf_validationformulas2 = asf_validationformulas.replace(/_$/, '')

                } else {
                    asf_validationformulas2 = null
                }

                if (asf_visible === false) {
                    asf_condition = 'return true;'
                    asf_validationformulas2 = null
                }



                if (asf_function === '') {
                    func = null
                } else {
                    func = asf_function
                }

            
                const dados = {
                    id: geraStringAleatoria(),
                    fte_id: props.fte_id || null,
                    fdm_id: fdm_id || null,
                    asf_active,
                    asf_visible,
                    asf_editable,
                    asf_acceptnull,
                    asf_description,
                    asf_label: asf_label || null,
                    asf_alternativeidentifier,
                    asf_tip: asf_tip || null,
                    asf_size: asf_size || null,
                    asf_observation: asf_observation || null,
                    asf_typelist: asf_typelist || null,
                    mmc_id: mmc_id || null,
                    asf_fontformat: asf_fontformat || null,
                    asf_fontsize: asf_fontsize || null,
                    asf_truevalue: asf_truevalue || null,
                    asf_falsevalue: asf_falsevalue || null,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                    age_id_lastupdate: null,
                    descriptionUp: descriptionUp || null,
                    alternativeidentifieUp: alternativeidentifieUp || null,
                    asf_condition: asf_condition,
                    asf_function: func,
                    asf_valueformulas: asf_valueformulas,
                    asf_labelvalueformulas: asf_labelvalueformulas,
                    asf_validationformulas: asf_validationformulas2,
                    asf_validationformulasobj: transfor
                }

                props.confirmarCampo(dados)

            } else {


                let asf_condition: null | string = "";
                let func: string | null = null;
                let transfor: any
                let asf_validationformulas: any | string = '';
                let asf_validationformulas2: any | string = '';


                if (asf_visible === true && transactionsList.length === 0) {
                    asf_condition = 'return true;'
                }

                if (transactionsList.length !== 0) {
                    
                        transactionsList.map((item: any, index: any) => {



                            if (item.tipo === 'campo') {
                                asf_condition += 'formData.' + item.id; // Adiciona um espaço após
                                asf_validationformulas += item.id + '_'
                                
                            } else if (item.tipo === 'operadorComparacao') {
                                asf_condition += item.id; // Adiciona um espaço após
                            } else if (item.tipo === 'constante') {
                                asf_condition += '"' + item.id + '" '; // Adiciona um espaço após
                            } else if (item.tipo === 'operador') {
                                asf_condition += item.id; // Adiciona um espaço após
                            }



                        })
                    

                    asf_condition = 'return ' + asf_condition

                    transfor = JSON.stringify(transactionsList)

                    asf_validationformulas2 = asf_validationformulas.replace(/_$/, '')

                } else {
                    asf_validationformulas2 = null
                }

                if (asf_visible === false) {
                    asf_condition = 'return true;'
                    asf_validationformulas2 = null
                }



                if (asf_function === '') {
                    func = null
                } else {
                    func = asf_function
                }


                const dados = {
                    id: props.dados.id,
                    fte_id: props.fte_id || null,
                    fdm_id: fdm_id || null,
                    asf_active,
                    asf_visible,
                    asf_editable,
                    asf_acceptnull,
                    asf_description,
                    asf_label: asf_label || null,
                    asf_alternativeidentifier,
                    asf_tip: asf_tip || null,
                    asf_size: asf_size || null,
                    asf_observation: asf_observation || null,
                    asf_typelist: asf_typelist || null,
                    mmc_id: mmc_id || null,
                    asf_fontformat: asf_fontformat || null,
                    asf_fontsize: asf_fontsize || null,
                    asf_truevalue: asf_truevalue || null,
                    asf_falsevalue: asf_falsevalue || null,
                    age_id_insert: props.dados.age_id_insert || null,
                    age_id_lastupdate: secureLocalStorage.getItem('iag'),
                    descriptionUp: descriptionUp || null,
                    alternativeidentifieUp: alternativeidentifieUp || null,
                    asf_condition: asf_condition,
                    asf_function: func,
                    asf_valueformulas: asf_valueformulas,
                    asf_labelvalueformulas: asf_labelvalueformulas,
                    asf_validationformulas: asf_validationformulas2,
                    asf_validationformulasobj: transfor
                }

              
                props.confirmarCampo(dados)

            }
           
        }

    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    function OpenModalIncluirNovo2() {

        if (totalCampos.length > 0) {
            setOpenModalIncluirNovo2(true)
        } else {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não há campos disponíveis para criar a fórmula de validação.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        }

    }

    function fecharOpenModalIncluirNovo2() {
        setOpenModalIncluirNovo2(false)
    }

    function OpenModalIncluirNovo() {
        setOpenModalIncluirNovo(true)
    }

    return (
        <>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

                <div className="container-cad-prod42">
                    <div id="content1">
                        <TabList>
                            <Tab>
                                <p>Informações</p>
                            </Tab>
                            <Tab>
                                <p>Fórmulas de Valor</p>
                            </Tab>
                            <Tab>
                                <p>Fórmulas de Validação</p>
                            </Tab>
                        </TabList>
                    </div>



                    <div id="content2">
                        <TabPanel>
                            <div className="panel-content">

                                <div className='panel-content-visu2'>

                                    <div className="container-modal-pric">
                                        <div className="container-cad-dash1-act">
                                            <div id="content1">
                                                <div className="class-checkbox">
                                                    <input type="checkbox" id="checkbox-active-active" checked={asf_active} onChange={ValitarActive} />
                                                    <h4 className="lab-ativo">Ativo</h4>
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="class-checkbox">
                                                    <input type="checkbox" id="checkbox-active-visible" checked={asf_visible} onChange={ValitarVisible} />
                                                    <h4 className="lab-ativo">Visível</h4>
                                                </div>
                                            </div>
                                            <div id="content3">
                                                <div className="class-checkbox">
                                                    <input type="checkbox" id="checkbox-active-editable" checked={asf_editable} onChange={ValitarEditable} />
                                                    <h4 className="lab-ativo">Editável</h4>
                                                </div>
                                            </div>
                                            <div id="content4">
                                                <div className="class-checkbox">
                                                    <input type="checkbox" id="checkbox-active-acceptnull" checked={asf_acceptnull} onChange={ValitarAcceptnull} />
                                                    <h4 className="lab-ativo">Aceita nulo</h4>
                                                </div>
                                            </div>
                                        </div>

                                       


                                        <div className="container-cad-prod4">
                                            <div id="content1">
                                                <div className="input-box-ativos">
                                                    <span className="details">Descrição *</span>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setAsf_description(e.target.value)}
                                                        value={asf_description}
                                                        autoComplete="nope"
                                                    />
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box-ativos">
                                                    <span className="details">Label</span>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setAsf_label(e.target.value)}
                                                        value={asf_label}
                                                        autoComplete="nope"
                                                    />
                                                </div>
                                            </div>
                                        </div>




                                        <div className="container-cad-prod4">
                                            <div id="content1">
                                                <div className="input-box-ativos">
                                                    <span className="details">Identificador alternativo *</span>
                                                    <input
                                                        type="text"
                                                       	 onChange={handleInputChange}
                                                        value={asf_alternativeidentifier}
                                                        autoComplete="nope"
                                                    />
                                                </div>
                                            </div>


                                            <div id="content2">
                                                <div className="input-box-ativos">
                                                    <span className="details">Dica</span>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setAsf_tip(e.target.value)}
                                                        value={asf_tip}
                                                        autoComplete="nope"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="container-cad-prod3">
                                            <div className="textareaBox-ativo">
                                                <span className="details">Observação</span>
                                                <textarea
                                                    onChange={(e) => setSf_observation(e.target.value)}
                                                    value={asf_observation}
                                                />
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </TabPanel>

                        <TabPanel>
                            <>
                                <div className="title-Page">
                                    <div className="title-text">
                                        <div className="size-x circle">1</div>
                                        <h2>Montar Fórmula de Valor</h2>
                                    </div>
                                    <hr />
                                </div>

                                <div className="btn-resultado-formula">
                                    <button className="botao-resultado-formula" onClick={OpenModalIncluirNovo}>
                                        Adicionar
                                    </button>

                                    <button className="botao-resultado-formula2" onClick={limparValorFormulaValor}>
                                        Limpar
                                    </button>
                                </div>

                                <div className="resultado-formula">
                                    <h1 className='valor-resultado-formula'>Campo Recebe = {asf_labelvalueformulas}</h1>
                                </div>
                            </>

                        </TabPanel>
                        <TabPanel>
                            <>
                                <div className="title-Page">
                                    <div className="title-text">
                                        <div className="size-x circle">1</div>
                                        <h2>Montar Fórmula de Validação</h2>
                                    </div>
                                    <hr />
                                </div>

                                <div className="btn-resultado-formula">
                                    <button className="botao-resultado-formula" onClick={OpenModalIncluirNovo2}>
                                        Adicionar
                                    </button>

                                    <button className="botao-resultado-formula2" onClick={limparValorFormulaValor2}>
                                        Limpar
                                    </button>
                                </div>

                                <div className="resultado-formula">

                                    {transactionsList.map((item: any, index: any) => (
                                        <h1 key={index} className='valor-resultado-formula'>{item.id}</h1>
                                    ))}

                                </div>
                            </>
                        </TabPanel>
                        <div className="Bt-button-cad-atv">
                            <div className="Bt-button-cad">
                                <button type="submit" onClick={salvarCampo}>
                                    <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                                </button>
                                <button type="submit" onClick={() => props.fecharOpenModalIncluirNovo()}>
                                    <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Tabs>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">Selecione um operando:</h1>
                        <div onClick={fecharOpenModalIncluirNovo}>
                            <button className="fa fa-times icone-modal-IncluirNovo" />
                        </div>

                    </div>

                    <div className='container-modal-incluir'>
                        <Accordion data={data} pegarValorFormulaValor={pegarValorFormulaValor} />
                    </div>
                </>
            </ModalIncluirNovo>


            <ModalIncluirNovo isOpen={openModalIncluirNovo2} setModalOpen={() => setOpenModalIncluirNovo2(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">Selecione um operando:</h1>
                        <div onClick={fecharOpenModalIncluirNovo2}>
                            <button className="fa fa-times icone-modal-IncluirNovo" />
                        </div>

                    </div>

                    <div className='container-modal-incluir'>
                        <Accordion data={optionsCampos}
                            etapasvalidationformulas={etapasvalidationformulas}
                            pegarValorFormulaValor={pegarValorFormulaValor2}
                            operadorComparacao={operadorComparacao}
                            operador={operador}
                        />
                    </div>
                </>
            </ModalIncluirNovo>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default FormDuracao;