import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import FormTipoEquipe from '../../components/FormTipoEquipe';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useParams } from 'react-router-dom';
import ModalPrincipal from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function TeamEdit() {
	let { id } = useParams();
	const navigate = useNavigate();

	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [qualTitulo, setQualTitulo] = useState("")
	const [qualGrid, setQualGrid] = useState(<></>)

	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)

	const [active, setActive] = useState(true)
	const [integrationid, setIntegrationId] = useState('')
	const [description, setDescription] = useState('')
	const [teamsupervisor, setTeamsupervisor] = useState('')
	const [tet_id, setTet_id] = useState('')
	const [age_id_insert, setAge_id_insert] = useState('')
	const [age_id_lastupdate, setAge_id_lastupdate] = useState('')

	const [optionsTeamSupervisor, setOptionsTeamSupervisor] = useState([])
	const [optionsTeamType, setOptionsTeamType] = useState([])

	const [userSelecionada, setUserSelecionada] = useState([]);
	const [dadosUser, setDadosUser] = useState([]);

	const [descriptionDisabled, setDescriptionDisabled] = useState(false)
	const [integrationidDisabled, setIntegrationidDisabled] = useState(false)
	const [teamsupervisorDisabled, setTeamsupervisorDisabled] = useState(false)
	const [tet_idDisabled, setTet_idDisabled] = useState(false)
	const [dualListBoxDisabled, setDualListBoxDisabled] = useState(false)
	const [confirmarDisabled, setConfirmarDisabled] = useState(false)
	const [activeDisabled, setActiveDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)

	const [tipoUser, setTipoUser] = useState('')
	const [optionsTeam, setOptionsTeam] = useState([])

	const [descriptionIni, setDescriptionIni] = useState('')
	const [integrationidIni, setIntegrationidIni] = useState('')

	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		setUserSelecionada([]);
		setDadosUser([]);

		validatToken();



	}, []);

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				GetTeamSupervisor();
				GetTeamType();
				await GetUser();
				await usereditar();
				GetTeam();

				if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {

					setTipoUser(ret.data.decodedToken.acp_integrationid)
					setDescriptionDisabled(false)
					setIntegrationidDisabled(false)
					setTeamsupervisorDisabled(false)
					setTet_idDisabled(false)
					setDualListBoxDisabled(false)
					setConfirmarDisabled(false)
					setActiveDisabled(false)
					setCancelarDisabled(false)
				} else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {

					setTipoUser(ret.data.decodedToken.acp_integrationid)
					setDescriptionDisabled(true)
					setIntegrationidDisabled(true)
					setTeamsupervisorDisabled(true)
					setTet_idDisabled(true)
					setDualListBoxDisabled(true)
					setConfirmarDisabled(true)
					setActiveDisabled(true)
					setCancelarDisabled(true)
				}


			}

		} catch (e: any) {

			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}

	}


	async function GetTeam() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("team/verificacaoteam")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsTeam(valor)

			}
		} catch (e) {
			setOptionsTeam([])
		}
	}

	function fecharModal() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}


	async function usereditar() {
		let ret: AxiosResponse;
		let valor: any;


		if (/^\d+$/.test(id || '') === true) {
			try {
				ret = await api.get("team/usereditar", {
					params: {
						id: id
					}
				})

				if (ret.data.status === 200) {

					const newarray = ret.data.data.userEquipe.map((element: any) => {
						return String(element.age_id)
					})

					setUserSelecionada(newarray)

	
					setActive(ret.data.data.listar[0].tae_active != undefined ? Boolean(ret.data.data.listar[0].tae_active) : true)
					setIntegrationId(ret.data.data.listar[0].tae_integrationid || '')
					setDescription(ret.data.data.listar[0].tae_description || '')

					setDescriptionIni(ret.data.data.listar[0].tae_description || '')
					setIntegrationidIni(ret.data.data.listar[0].tae_integrationid || '')

					if (ret.data.data.listar[0].tea_teamsupervisor !== null) {
						setTeamsupervisor(String(ret.data.data.listar[0].tea_teamsupervisor || ''))
					}
					setTet_id(String(ret.data.data.listar[0].tet_id || ''))

					setOpenModal(false)

				} else {
					setOpenModal(false)
					navigate('/team/edit/' + id + '/n');
				}
			} catch (e) {
				setOpenModal(false)
				navigate('/team/edit/' + id + '/n');
			}

		} else {
			setOpenModal(false)
			navigate('/team/edit/' + id + '/n');
		}
	}

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	async function GetTeamSupervisor() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("team/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;


				const Filtrarteam = valor.filter(
                    (e: any) => {
                        return String(e.tae_id) !== String(id)
                    }
                );

				const arr = [] as any;
				let result = Filtrarteam;
				result.map((user: any) => {
					return arr.push({ value: String(user.tae_id), label: user.tae_description });
				});
				setOptionsTeamSupervisor(arr)

			}
		} catch (e) {
			setOptionsTeamSupervisor([])
		}
	}

	async function GetTeamType() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("teamtype/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.tet_id), label: user.tet_description });
				});
				setOptionsTeamType(arr)

			}
		} catch (e) {
			setOptionsTeamType([])
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	async function ChamarModalEquipe() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				if (secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR') {
					setOpenModalIncluirNovo(true)

					sessionStorage.removeItem("dadosTipoAgente");
					setQualTitulo('Cadastrar Tipo de Equipe')
					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
						th1='Ativo'
						th2='ID'
						th3='Descrição'
						th4='Identificador Alternativo'
						th5=''
						th6=''
						endPoint='teamtype/buscarteamtype'
						consult='N'
						col1='1'
						col2='2'
						col3='3'
						ambiente='%'
						pag="ok"
					/></>)
					)

				}

				setOpenModal(false)
			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)

			}
		}

	}

	function PegarValorGrid(valor: any) {

		setTet_id(String(valor.tet_id))
		setOpenModalIncluirNovo(false)

	}

	function ChamarCadastroTipoEquipe() {
		sessionStorage.removeItem("dadosTipoEquipe");
		setQualGrid(<><FormTipoEquipe voltar={BtnVoltarTipoEquipe} /></>)
	}

	function BtnVoltarTipoEquipe() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
			th1='Ativo'
			th2='ID'
			th3='Descrição'
			th4='Identificador Alternativo'
			th5=''
			th6=''
			endPoint='teamtype/buscarteamtype'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente='%'
			pag="ok"
		/></>)
		)
		GetTeamType()

	}

	async function EditarTipoEquipe(obj: any) {
		sessionStorage.setItem("dadosTipoEquipe", JSON.stringify(obj));
		setQualGrid(<><FormTipoEquipe voltar={BtnVoltarTipoEquipe} /></>)
	}

	function TipoEquipeDeletar(obj: any) {
		setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarEditarTipoEquipe(obj.tet_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)
	}

	async function deletarEditarTipoEquipe(id: any) {
		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)
		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;
		try {
			ret = await api.delete("teamtype/delete", {
				data: { id: id }
			});
			if (ret.data.status === 200) {
				setOptionsTeamType([])
				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
					th1='Ativo'
					th2='ID'
					th3='Descrição'
					th4='Identificador Alternativo'
					th5=''
					th6=''
					endPoint='teamtype/buscarteamtype'
					consult='N'
					col1='1'
					col2='2'
					col3='3'
					ambiente='%'
					pag="ok"
				/></>)
				)
				GetTeamType()
			}
		} catch (e: any) {
			setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
				th1='Ativo'
				th2='ID'
				th3='Descrição'
				th4='Identificador Alternativo'
				th5=''
				th6=''
				endPoint='teamtype/buscarteamtype'
				consult='N'
				col1='1'
				col2='2'
				col3='3'
				ambiente='%'
				pag="ok"
			/></>)
			)
			GetTeamType()
		}
		setOpenModal(false);
	}

	async function GetUser() {
		let ret: AxiosResponse;
		let valor: any;

		try {
			ret = await api.get("user/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				setDadosUser(valor);

			}
		} catch (e: any) {

		}
	}

	let options = dadosUser.map((element: any) => {
		return { value: String(element.age_id), label: element.age_name }
	});

	function valor(selected: any) {

		setUserSelecionada(selected)

	}

	async function insertEquipe() {
		let ret: AxiosResponse;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		let Filtrardescricao = []
		let Filtrarintegrationid = []

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				Filtrardescricao = optionsTeam.filter(
					(e: any) => {
						return e.tae_description.toLowerCase() === description.toLowerCase()
					}
				);

				Filtrarintegrationid = optionsTeam.filter(
					(e: any) => {
						return e.tae_integrationid.toLowerCase() === integrationid.toLowerCase()
					}
				);

				if (descriptionIni.toLowerCase() !== description.toLowerCase() && Filtrardescricao.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">A descrição já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else
					if (integrationidIni.toLowerCase() !== integrationid.toLowerCase() && Filtrarintegrationid.length > 0) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">O ID para integração já está em uso.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)

					} else

						if (description === "") {
							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)
						} else
						if (integrationid === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o ID para Integração.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)

						} 
							
						else if (userSelecionada.length === 0) {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Selecione ao menos uma pessoa a equipe.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)
						} else {

							const dados = {
								tae_id: id,
								tae_integrationid: integrationid,
								tae_description: description,
								tae_active: active,
								tea_teamsupervisor: teamsupervisor || null,
								tet_id: tet_id || null,
								age_id_lastupdate: secureLocalStorage.getItem('iag'),
							}

							try {
								ret = await api.put("team/update", {
									data: dados,
									dadosuser: userSelecionada,
								});

								if (ret.data.status === 200) {
									setQualAlert(<div className='div-alert'>
										<span className="fa-regular fa-circle-check ControleIconAlert"></span>
										<div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso!</span></div>
										<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
									</div>)

								}
							} catch (e: any) {
								setQualAlert(<div className='div-alert'>
									<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
									<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
									<div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
									<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
								</div>)
							}
						}

			}

		} catch (e: any) {

			
			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}
	}

	function fecharModal2() {
		setOpenModal(false)
	}

	function finalizarModal1() {
		navigate('/team');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

	function cancelar() {
		navigate('/team');
	}

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" disabled={activeDisabled} checked={active} onChange={ValitarActive} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>
						<div className="container-cad-prod6">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										type="text"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
										autoComplete="nope"
										disabled={descriptionDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">ID para Integração *</span>
									<input
										type="text"
										onChange={(e) => setIntegrationId(e.target.value)}
										value={integrationid}
										autoComplete="nope"
										disabled={integrationidDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="select-box-ativos-select">
									<span className="details">Equipe Responsável</span>
									<Select
										placeholder={'Selecione...'}
										options={optionsTeamSupervisor}
										styles={customStyles}
										value={optionsTeamSupervisor.filter(function (option: any) {
											return option.value === teamsupervisor;
										})}
										onChange={(options: any) =>
											!options ? setTeamsupervisor("") : setTeamsupervisor(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={teamsupervisorDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Tipo</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' />
										<span className="details-esc-ativo" onClick={() => ChamarModalEquipe()}>Incluir Novo</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsTeamType}
										styles={customStyles}
										value={optionsTeamType.filter(function (option: any) {
											return option.value === tet_id;
										})}
										onChange={(options: any) =>
											!options ? setTet_id("") : setTet_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={tet_idDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Pessoas vinculadas à equipe</h2>
							</div>
							<hr />
						</div>

						<div className='DualListBox'>
							<div className="input-box">
								<span className="details-list">USUÁRIOS DISPONÍVEIS:</span>
							</div>
							<DualListBox
								options={options}
								selected={userSelecionada}
								onChange={valor}
								icons={{
									moveLeft: "<",
									moveAllLeft: "<<",
									moveRight: ">",
									moveAllRight: ">>"
								}}
								preserveSelectOrder={true}
								disabled={dualListBoxDisabled}
							/>
						</div>

						<div className="Bt-button-cad">
							<button type="submit" onClick={insertEquipe} disabled={confirmarDisabled}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>

			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
						<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>

						<div onClick={fecharOpenModalIncluirNovo}>
							<button className="fa fa-times icone-modal-IncluirNovo" />
						</div>
					</div>

					<div className='scrol-modal'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>

				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

		</>
	);

}

export default TeamEdit;